import React, { useState, useEffect } from 'react';
import { db, auth } from './firebase-config';
import { collection, query, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import DataTable from 'react-data-table-component';
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';

function Dashboard() {
    const [places, setPlaces] = useState([]);
    const navigate = useNavigate();
    const [filterText, setFilterText] = useState('');

    useEffect(() => {
        const q = query(collection(db, "places"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const places = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setPlaces(places);
        });

        return () => unsubscribe();
    }, []);

    const filteredItems = places.filter(
        item => item?.name?.toLowerCase().includes(filterText.toLowerCase())
    );

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this place?")) {
            const placeDocRef = doc(db, "places", id);
            await deleteDoc(placeDocRef);
            // Refresh the places list after deletion
            const updatedPlaces = places.filter(place => place.id !== id);
            setPlaces(updatedPlaces); // Optimistically remove the place from the UI
        }
    };

    const columns = [
        {
            name: 'Image',
            selector: row => row.imageUrl,
            cell: row => <img height="60" alt={row.name} src={row.imageUrl} />,
            ignoreRowClick: true,
        },
        {
            name: 'Place Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                        className="btn btn-secondary me-1"
                        onClick={() => navigate(`/blog/${row.id}`)} // Assuming /blog/:id is the route for viewing a blog post
                    >
                        View
                    </button>
                    {' '}
                    <button
                        className="btn btn-primary me-1"
                        onClick={() => navigate(`/edit/${row.id}`)}
                    >
                        Edit
                    </button>
                    {' '}
                    <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(row.id)}
                    >
                        Delete
                    </button>
                </div>
            ),
            ignoreRowClick: true,
            style: {
                flex: 1
            },
        },
    ];

    return (
        <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">Paris Stories</Link>
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" to="/add">Add New Place</Link>
                            </li>
                            <li className="nav-item">
                                <button className="btn" onClick={handleSignOut}>Sign Out</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <h1>Dashboard</h1>
            <hr />
            <div>
                <input
                    type="text"
                    placeholder="Search Places..."
                    className="form-control"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
                <DataTable
                    title="Places List"
                    columns={columns}
                    data={filteredItems}
                    pagination
                // You can also enable other features like:
                // paginationPerPage={5}
                // paginationRowsPerPageOptions={[5, 10, 15, 20]}
                // selectableRows
                // onRowClicked={handleRowClick}
                // etc.
                />
            </div>
        </div>
    );
}

export default Dashboard;
