// BlogPost.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from './firebase-config';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';

function BlogPost() {
    const [post, setPost] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    useEffect(() => {
        const fetchPost = async () => {
            const docRef = doc(db, "places", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setPost(docSnap.data());
            } else {
                console.log("No such document!");
            }
        };

        fetchPost();
    }, [id]);

    return (
        <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">Paris Stories</Link>
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" to="/add">Add New Place</Link>
                            </li>
                            <li className="nav-item">
                                <button className="btn" onClick={handleSignOut}>Sign Out</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {post ? (
                <>
                    <h1>{post.name}</h1>
                    {/* Display the header image if it exists */}
                    <div
                        style={{
                            width: '100%',
                            maxHeight: '500px',
                            minHeight: '300px',
                            backgroundImage: `url(${post.imageUrl})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                    />
                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default BlogPost;
