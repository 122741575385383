import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, auth, storage } from './firebase-config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import stylesheets
import { signOut } from 'firebase/auth';
import DOMPurify from 'dompurify';

function EditPlaceForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [content, setContent] = useState('');
    const [editMode, setEditMode] = useState('rich-text'); // 'rich-text' or 'code'
    const [imageUrl, setImageUrl] = useState('');
    const [file, setFile] = useState(null); // For handling the new image file
    const [success, setSuccess] = useState(''); // State for success messages
    const [error, setError] = useState(''); // State for error messages
    const [createdAt, setCreatedAt] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');

    const sanitizeContent = (htmlContent) => {
        return DOMPurify.sanitize(htmlContent);
    };


    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean'],
            ['code-block'] // Here is where you add the 'code-block' button
        ],
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'code-block' // Ensure you include 'code-block' in the formats as well
    ];

    useEffect(() => {
        const fetchPlaceData = async () => {
            const docRef = doc(db, "places", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setName(docSnap.data().name);
                setContent(docSnap.data().content);
                setImageUrl(docSnap.data().imageUrl);
                const data = docSnap.data();
                setCreatedAt(data.createdAt ? data.createdAt.toDate().toLocaleString() : '');
                setUpdatedAt(data.updatedAt ? data.updatedAt.toDate().toLocaleString() : '');
            } else {
                console.error("No such document!");
                navigate("/");
            }
        };

        fetchPlaceData();
    }, [id, navigate]);

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            const newFile = e.target.files[0];
            setFile(newFile);
            setImageUrl(URL.createObjectURL(newFile)); // For preview purposes
        }
    };

    const handleRemoveImage = async () => {
        if (imageUrl) {
            // Optionally delete the current image from Firebase Storage if needed
            // const imageRef = ref(storage, imageUrl);
            // await deleteObject(imageRef);
            setImageUrl('');
            setFile(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let newImageUrl = imageUrl;

        if (file) {
            const imageRef = ref(storage, `places/${file.name}`);
            await uploadBytes(imageRef, file);
            newImageUrl = await getDownloadURL(imageRef);
        }

        try {
            const sanitizedContent = sanitizeContent(content); // Sanitize before saving

            const placeRef = doc(db, "places", id);
            await updateDoc(placeRef, {
                name,
                content: sanitizedContent, // Save the sanitized content
                imageUrl: newImageUrl, // Save the new image URL
                updatedAt: new Date(),
            });
            setSuccess("Changes saved successfully!"); // Set a success message
            // Optionally reset error state
            setError('');
        }
        catch (error) {
            // Update state with error message
            setError("Error saving changes: " + error.message);
            // Optionally reset success message
            setSuccess('');
        }
    };

    // Function to navigate to the View Post page
    const handleViewPost = () => {
        navigate(`/blog/${id}`);
    };

    return (
        <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">Paris Stories</Link>
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" to="/add">Add New Place</Link>
                            </li>
                            <li className="nav-item">
                                <button className="btn" onClick={handleSignOut}>Sign Out</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <h2>Edit Place</h2>
            <div className="edit-place-form">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Place Name</label>
                        <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        {imageUrl && (
                            <>
                                <img src={imageUrl} alt="Header" style={{ maxWidth: '100%', maxHeight: '300px' }} /><br />
                                <button type="button" className="btn btn-danger mt-2" onClick={handleRemoveImage}>Remove Image</button>
                            </>
                        )}
                        {!imageUrl && (
                            <>
                                <label htmlFor="newImage" className="form-label">Upload New Image</label>
                                <input type="file" className="form-control" id="newImage" onChange={handleImageChange} />
                            </>
                        )}
                    </div>
                    <div className="mb-3">

                        <div className="d-flex justify-content-between">
                            <label htmlFor="content" className="form-label">Content</label>
                            <button type="button" className="btn btn-outline-secondary mb-2" onClick={() => setEditMode(prevMode => prevMode === 'rich-text' ? 'code' : 'rich-text')}>
                                Toggle Editor Mode
                            </button>
                        </div>
                        {editMode === 'rich-text' ? (
                            <ReactQuill
                                theme="snow"
                                value={content}
                                onChange={setContent}
                                modules={modules}
                                formats={formats}
                            />
                        ) : (
                            <textarea
                                className="form-control"
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                style={{ height: '400px' }}
                            />
                        )}
                    </div>


                    <button type="submit" className="btn btn-primary me-1">Save Changes</button>
                    <button type="button" className="btn btn-secondary" onClick={handleViewPost}>View Post</button>
                    {/* Success and Error messages */}
                    {success && <div className="alert alert-success mt-2">{success}</div>}
                    {error && <div className="alert alert-danger mt-2">{error}</div>}
                    {createdAt && (
                        <div className="text-muted mt-5"><small>Created at: {createdAt}</small></div>
                    )}
                    {updatedAt && (
                        <div className="text-muted"><small>Last updated at: {updatedAt}</small></div>
                    )}
                </form>
            </div>
        </div>
    );
}

export default EditPlaceForm;
