import React, { useState } from 'react';
import { auth } from './firebase-config';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Clear any existing errors
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Redirect or do something upon successful login
    } catch (error) {
      console.error(error);
      setError('Failed to log in. Please check your email and password.');
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setError('Please enter your email address for password reset.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setError('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error(error);
      setError('Failed to send password reset email. Please try again.');
    }
  };

  return (
    <div className="container">
      <form onSubmit={handleLogin} className="mb-3">
        <h3>Log In</h3>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="mb-3">
          <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
        </div>
        <div className="mb-3">
          <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
        </div>
        <button type="submit" className="btn btn-primary">Login</button>
      </form>
      <div>
        <button onClick={handlePasswordReset} className="btn btn-link">Forgot Password?</button>
      </div>
    </div>
  );
}

export default Login;
