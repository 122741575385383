import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import AddPlaceForm from './AddPlaceForm';
import { auth } from './firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';
import EditPlaceForm from './EditPlaceForm';
import BlogPost from './BlogPost';
import 'react-quill/dist/quill.snow.css'; // Import for react-quill styles
import 'bootstrap/dist/css/bootstrap.min.css'; // Import for Bootstrap styles

function App() {
  const [user] = useAuthState(auth);

  return (
    <Router>
      <Routes>
        <Route path="/" element={user ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path="/add" element={user ? <AddPlaceForm /> : <Navigate to="/login" />} />
        <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />
        {/* Redirect to login if no routes match and user is not authenticated */}
        <Route path="*" element={!user ? <Navigate to="/login" /> : <Navigate to="/" />} />
        <Route path="/edit/:id" element={user ? <EditPlaceForm /> : <Navigate to="/login" />} />
        <Route path="/blog/:id" element={<BlogPost />} />
      </Routes>
    </Router>
  );
}

export default App;
