// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyA_eNPyToRv6EDY6Ij8jzFLnTRxHu_g4cY",
    authDomain: "paris-stories.firebaseapp.com",
    databaseURL: "https://paris-stories-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "paris-stories",
    storageBucket: "paris-stories.appspot.com",
    messagingSenderId: "91635666909",
    appId: "1:91635666909:web:124647fe1db0eb4443f034",
    measurementId: "G-GKJM187BZC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

// Export the necessary modules
export { db, storage, auth };
