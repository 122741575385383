import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import stylesheets
import DOMPurify from 'dompurify';
import { db, auth, storage } from './firebase-config';
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';

function AddPlaceForm() {
    const [content, setContent] = useState('');
    const [name, setName] = useState('');
    const [image, setImage] = useState(null); // Image file state
    const [imageUrl, setImageUrl] = useState(null); // Image URL for preview
    const [uploading, setUploading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [editMode, setEditMode] = useState('rich-text'); // New state for toggling editor mode
    const navigate = useNavigate();

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean'],
            ['code-block'] // Here is where you add the 'code-block' button
        ],
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'code-block' // Ensure you include 'code-block' in the formats as well
    ];

    // Function to sanitize HTML content
    const sanitizeContent = (htmlContent) => {
        return DOMPurify.sanitize(htmlContent);
    };

    const handleContentChange = (htmlContent) => {
        const sanitizedContent = sanitizeContent(htmlContent);
        setContent(sanitizedContent);
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            const file = e.target.files[0];
            setImage(file);
            setImageUrl(URL.createObjectURL(file)); // For preview purposes
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name || !content || !image) {
            setError('Please fill out all fields and select an image.');
            return;
        }
        setUploading(true);
        setError('');
        setSuccess('');

        // Create a unique image name
        const imageName = `${Date.now()}-${image.name}`;
        const imageRef = ref(storage, `images/${imageName}`);

        try {
            // Upload the image to Firebase Storage
            const snapshot = await uploadBytes(imageRef, image);
            // Get the URL of the uploaded file
            const imageUrl = await getDownloadURL(snapshot.ref);
            // Save the place data to Firestore
            await addDoc(collection(db, "places"), {
                name,
                content: sanitizeContent(content), // Sanitize before saving
                imageUrl, // Changed from headerImageUrl to imageUrl to match your Firestore field
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
            });
            setSuccess("Place added successfully!");
            setName('');
            setContent('');
            setImage(null);
            setImageUrl(null); // Clear the image URL after successful upload
        } catch (uploadError) {
            setError("Error adding place: " + uploadError.message);
        }
        setUploading(false);
    };

    return (
        <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">Paris Stories</Link>
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <button className="btn" onClick={handleSignOut}>Sign Out</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <h1>Add New Place</h1>
            <hr />
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
            {success && <div className="alert alert-success" role="alert">{success}</div>}
            <form onSubmit={handleSubmit} className="mb-3">
                <div className="mb-3">
                    <label htmlFor="placeName" className="form-label">Place Name</label>
                    <input type="text" className="form-control" id="placeName" value={name} onChange={(e) => setName(e.target.value)} placeholder="Place Name" required />
                </div>
                <div className="mb-3">
                    <label htmlFor="placeImage" className="form-label">Image</label>
                    {imageUrl && <img src={imageUrl} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />}
                    <input type="file" className="form-control" id="placeImage" onChange={handleImageChange} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="content" className="form-label">Content</label>
                    <div>
                        <button type="button" className="btn btn-outline-secondary mb-2" onClick={() => setEditMode(prevMode => prevMode === 'rich-text' ? 'code' : 'rich-text')}>
                            Toggle Editor Mode
                        </button>
                    </div>
                    {editMode === 'rich-text' ? (
                        <ReactQuill
                            theme="snow"
                            value={content}
                            modules={modules}
                            formats={formats}
                            onChange={handleContentChange} />
                    ) : (
                        <textarea className="form-control" value={content} onChange={(e) => setContent(e.target.value)} style={{ height: '400px' }} />
                    )}
                </div>

                <button type="submit" className="btn btn-primary" disabled={uploading}>{uploading ? 'Uploading...' : 'Add Place'}</button>
            </form>
        </div>
    );
}

export default AddPlaceForm;
